import React from "react"

const Heading = ({
  preHeading = "pre heading",
  heading = "Heading",
  center,
  noBorder,
}) => {
  return (
    <div className={center && "flex flex-col items-center text-center"}>
      <span className="uppercase font-josefin-sans text-sm tracking-wide text-beshaus-preheading-text">
        [ {preHeading} ]
      </span>
      <h2 className="text-5xl mt-4">{heading}</h2>
      {!noBorder && (
        <div className="w-16 mt-8 border-dotted border-t-2 border-white"></div>
      )}
    </div>
  )
}

export default Heading
