import React from "react"

const values = [
  {
    name: "Thiết kế và lập kế hoạch",
    description: "Đưa ra thiết kế đảm bảo thẩm mỹ và kỹ thuật.",
    iconClassName: "flaticon-diamond",
  },
  {
    name: "Quản lý dự án",
    description:
      "Lựa chọn và đánh giá nhà thầu, phối hợp với nhà thầu, lựa chọn vật liệu hoàn thiện, nội thất, thiết bị.",
    iconClassName: "flaticon-think-outside-the-box",
  },
  {
    name: "Thi công hoàn thiện",
    description:
      "Chúng tôi sẽ nhận làm tổng thầu giai đoạn hoàn thiện công trình (với những công trình chúng tôi thiết kế) khi khách hàng có nhu cầu.",
    iconClassName: "flaticon-cube-1",
  },
]

const OneValue = ({ name, description, iconClassName }) => {
  return (
    <div className="px-8 py-6 border border-opacity-20 transition-colors duration-300 ease-linear hover:bg-black">
      <div className="flex">
        <i className={`${iconClassName} before:text-[45px] mr-6`}></i>
        <div>
          <h3 className="text-2xl">{name}</h3>
          <p className="mt-3 text-beshaus-body-text leading-[30px] font-raleway">
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}

const Values = () => {
  return (
    <section className="bg-[#1A1A1A]">
      <div className="container mx-auto max-w-7xl px-6 md:px-12 py-12 md:py-20">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {values.map(({ name, description, iconClassName }, index) => (
            <OneValue
              key={`value-${index}`}
              name={name}
              description={description}
              iconClassName={iconClassName}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Values
