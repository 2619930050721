import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Heading from "../Heading"

const principles = [
  {
    name: "Hợp đồng thiết kế",
    steps: [
      "Gặp gỡ trao đổi với khách hàng",
      "Khảo sát hiện trạng",
      "Phương án sơ bộ",
      "Báo giá thiết kế",
    ],
  },
  {
    name: "Thiết kế ý tưởng",
    steps: [
      "Tư vấn về phong cách, giải pháp, kỹ thuật",
      "Mặt bằng thiết kế cơ sở (có các giải pháp kỹ thuật)",
    ],
  },
  {
    name: "Thiết kế cơ sở",
    steps: [
      "Thống nhất các bản vẽ về mặt bằng, các giải pháp kỹ thuật",
      "Phương án 3D không gian kiến trúc, nội thất",
    ],
  },
  {
    name: "Thiết kế bản vẽ thi công",
    steps: [
      "Bản vẽ kỹ thuật phục vụ thi công",
      "Đề xuất các yêu cầu để lựa chọn nhà thầu",
      "Dự toán",
    ],
  },
  {
    name: "Bàn giao hồ sơ thiết kế",
    steps: [],
  },
  {
    name: "Giám sát tác giả, quản lý dự án",
    steps: [
      "Hỗ trợ chủ nhà trong quá trình thi công xây dựng, kiểm tra thi công và thiết kế",
      "Quản lý dự án",
      "Thi công hoàn thiện (nếu có)",
    ],
  },
]

const OnePrinciple = ({ name, steps = [], number }) => {
  return (
    <div className="space-y-2">
      <span className="block text-xl text-[#8F8F8F]">{number}.</span>
      <h3 className="text-xl">{name}</h3>
      <div className="font-raleway text-beshaus-body-text space-y-2">
        {steps.map((step, index) => (
          <p className={`step-${index}`}>- {step}</p>
        ))}
      </div>
    </div>
  )
}

const Principles = () => {
  return (
    <section className="bg-[#0A0A0A]">
      <div className="grid grid-cols-1 md:grid-cols-2 items-center">
        <div className="w-full h-full">
          <StaticImage
            src="../../assets/images/services-principles-section.jpg"
            className="w-full h-full"
            quality="100"
            placeholder="blurred"
            alt="about image"
          />
        </div>
        <div className="p-6 md:p-12">
          <Heading
            preHeading="our working process"
            heading="Quy trình làm việc"
          />
          <div className="mt-8 grid grid-cols-2 gap-8">
            {principles.map(({ name, steps }, index) => (
              <OnePrinciple
                key={`principle-${index}`}
                number={String(index + 1).padStart(2, "0")}
                name={name}
                steps={steps}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Principles
