import React from "react"

import Heading from "../Heading"

const benefits = [
  {
    name: "Tập trung vào người sử dụng",
    description:
      "Thấu hiểu khách hàng muốn gì, cá tính, lối sống để đưa ra giải pháp phù hợp.",
    iconClassName: "flaticon-sphere",
  },
  {
    name: "Chuyên nghiệp",
    description: "Làm việc theo quy trình, thiết kế theo từng bước.",
    iconClassName: "flaticon-measuring",
  },
  {
    name: "Kinh nghiệm",
    description:
      "Nắm rõ các tiêu chuẩn, quy chuẩn, thẩm mỹ và tỉ lệ trong thiết kế để xây dựng giải pháp phù hợp nhất với từng công trình.",
    iconClassName: "flaticon-home",
  },
  {
    name: "Giám sát tác giả",
    description:
      "Có trách nhiệm với thiết kế, luôn theo sát và hỗ trợ khách hàng đến khi đi vào sử dụng.",
    iconClassName: "flaticon-measure",
  },
]

const OneBenefit = ({ name, description, iconClassName }) => {
  return (
    <div>
      <i className={`${iconClassName} before:text-[54px]`}></i>
      <h3 className="mt-6 text-2xl">{name}</h3>
      <p className="mt-4 text-beshaus-body-text leading-[30px] font-raleway">
        {description}
      </p>
    </div>
  )
}

const Benefits = () => {
  return (
    <section className="bg-[#0A0A0A]">
      <div className="container mx-auto max-w-7xl px-6 md:px-12 py-12 md:py-20">
        <Heading
          preHeading="our benefits"
          heading="Thiết kế sáng tạo và ý tưởng thực tế"
        />
        <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-12">
          {benefits.map(({ name, description, iconClassName }, index) => (
            <OneBenefit
              key={`benefit-${index}`}
              name={name}
              description={description}
              iconClassName={iconClassName}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Benefits
