import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import SmallBanner from "../components/SmallBanner"
import Benefits from "../components/ServicesPage/Benefits"
import Values from "../components/ServicesPage/Values"
import Principles from "../components/ServicesPage/Principles"

const ServicesPage = () => {
  return (
    <>
      <SmallBanner
        title="Our Services"
        gatsbyImage={
          <StaticImage
            src="../assets/images/services-banner-background.jpg"
            className="w-full h-full"
            quality="100"
            placeholder="blurred"
            alt="services banner background"
          />
        }
      />
      <Benefits />
      <Values />
      <Principles />
    </>
  )
}

export default ServicesPage
